// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebase : {
    apiKey: 'AIzaSyB9mmONxJeHF2F3HIDxQqgqwkDheqyoSeo',
    authDomain: 'dydactico.firebaseapp.com',
    databaseURL: 'https://dydactico.firebaseio.com',
    projectId: 'dydactico',
    storageBucket: 'dydactico.appspot.com',
    messagingSenderId: '1024863714160',
    appId: '1:1024863714160:web:df8a7217c3c692cb60adb5',
    measurementId: 'G-BX6QQS2E6R'
  }

};
